import React from 'react';

import { Helmet } from 'react-helmet-async';
import { PageTitle, Section } from '../Common';
import Paper from '@mui/material/Paper';
import ItemList, { Item } from '@/components/Static/Common/ItemList';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const VerbsThatTakePrepositions = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Verbs That Take Prepositions</title>
        <link rel="canonical" href="https://spanishinaminute.com/guides/verbs-that-take-prepositions" />
        <meta name="description" content="List of Spanish verbs that need prepositions" />
        <meta name="keywords" content="Spanish, Grammar, Verbs, Prepositions" />
      </Helmet>
      <PageTitle variant="h4" component="h1">Verbs That Take Prepositions</PageTitle>
      <Section>
        <Paper>
          <ItemList>
            <Item title="Aburrirse con" description="To be bored with" />
            <Item title="Abstenerse de" description="To abstain from doing something" />
            <Item title="Abusar de" description="To take advantage of something" />
            <Item title="Acabar con" description="To finish off" />
            <Item title="Acordarse de" description="To remember something" />
            <Item title="Acostumbrarse a" description="To become used to something" />
            <Item title="Alcanzar a" description="To manage to" />
            <Item title="Aprender a" description="To learn to" />
            <Item title="Asistir a" description="To attend" />
            <Item title="Aspirar a" description="To aspire to" />
            <Item title="Atreverse a" description="To dare to" />
            <Item title="Ayudar a" description="To help to" />
            <Item title="Burlarse de" description="To make fun of" />
            <Item title="Cambiar de" description="To change" />
            <Item title="Cansarse con" description="To grow tired of" />
            <Item title="Casarse con" description="To marry" />
            <Item title="Comenzar a" description="To begin to" />
            <Item title="Condenar a" description="To condemn to" />
            <Item title="Confiar en" description="To trust" />
            <Item title="Confundir con" description="To confuse with" />
            <Item title="Consistir en" description="To consist of" />
            <Item title="Contar con" description="To count on" />
            <Item title="Convertirse en" description="To become" />
            <Item title="Decidirse a" description="To decide to" />
            <Item title="Dedicarse a" description="To devote to" />
            <Item title="Dejar de" description="To stop doing something" />
            <Item title="Depender de" description="To depend on" />
            <Item title="Despedirse de" description="To say goodbye to" />
            <Item title="Disculparse de" description="To apologize for" />
            <Item title="Disfrutar de" description="To enjoy something" />
            <Item title="Empezar a" description="To begin to" />
            <Item title="Enseñar a" description="To teach to" />
            <Item title="Insistir en" description="To insist on" />
            <Item title="Invitar a" description="To invite to" />
            <Item title="Llegar a" description="To succeed in" />
            <Item title="Mandar a" description="To send to" />
            <Item title="Meterse en" description="To be involved in" />
            <Item title="Montar en" description="To ride" />
            <Item title="Morir de" description="To die from" />
            <Item title="Negar a" description="To refuse to" />
            <Item title="Obligar a" description="To force to" />
            <Item title="Ocuparse de" description="To be busy with" />
            <Item title="Oler a" description="To smell like" />
            <Item title="Parar de" description="To stop doing something" />
            <Item title="Pensar de" description="To have an opinion about" />
            <Item title="Pensar en" description="To think about" />
            <Item title="Ponerse a" description="To begin to" />
            <Item title="Regresar a" description="To go back to" />
            <Item title="Saber a" description="To taste like" />
            <Item title="Salir a" description="To go out to" />
            <Item title="Servir de" description="To act as" />
            <Item title="Sonar a" description="To sound like" />
            <Item title="Soñar con" description="To dream about" />
            <Item title="Tarder en" description="To delay in" />
            <Item title="Terminar de" description="To finish" />
            <Item title="Venir a" description="To come to" />
            <Item title="Volver a" description="To go back to" />
          </ItemList>
        </Paper>
      </Section>
    </>
  );
};

export default VerbsThatTakePrepositions;
