import React from 'react';

import DropdownPractice from './DropdownPracticePage';
import { ParaPorCard } from '@/components/Practice/Cards';
import { Helmet } from 'react-helmet-async';

const ParaPorPracticePage = () => {
  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Para/Por</title>
        <meta name="description" content="Para/Por Grammar Exercises" />
        <meta name="keywords" content="Spanish, Grammar, Para, Por, FlashCard" />
        <link rel="canonical" href="https://spanishinaminute.com/para-por" />
      </Helmet>

      <DropdownPractice
        dataUrl="/api/v1/practice/questions/para-por"
        CardClass={ParaPorCard} />
    </>
  )
}

export default ParaPorPracticePage;
