import React from 'react';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { PageTitle, Section } from '@/components/Static/Common';
import { ImageWithSkeleton } from '@/components/Shared';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet-async';

const PhotoCell = styled(Grid)`
  text-align: center;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const VocabularyIndex = () => {
  const [documents] = useAllPrismicDocumentsByType('vocabulary_list', {
    graphQuery: `{
      vocabulary_list {
        display_name
        region
        poster
      }
    }`,
    orderings: [
      { field: "my.vocabulary_list.display_name", direction: "asc" }
    ]
  });

  return (
    <>
      <Helmet>
        <title>SpanishInAMinute - Vocabulary Lists</title>
        <meta name="description" content="Vocabulary lists for popular Spanish language tv shows on streaming platforms" />
        <link rel="canonical" href="https://spanishinaminute.com/vocabulary" />
      </Helmet>

      <PageTitle variant="h4">Vocabulary Lists</PageTitle>
      <Section>
        <Grid container rowSpacing={6} columnSpacing={2}>
          {documents && documents.map((document) => {
            return (
              <PhotoCell item xs={6} sm={4} lg={3} xl={2} key={document.uid}>
                <StyledLink component={RouterLink} underline="hover" variant="h6" to={document.uid}>
                  <ImageWithSkeleton height="275" width="200" alt={document.data.poster.alt} src={document.data.poster.url} />
                  <div>
                    {document.data.display_name}
                    {' '}
                    (
                    {document.data.region}
                    )
                    {' '}
                  </div>
                </StyledLink>
              </PhotoCell>
            );
          })}
        </Grid>
      </Section>
    </>
  );
};

export default VocabularyIndex;
