import React from 'react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const PageTitle = styled((props) => <Typography {...props} component="h1" />)`
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid gray;
`;


export const Section = styled.div`
  margin-bottom: 20px;
`;
