import React from 'react';
import styled from 'styled-components';

const ByLineBlock = styled('div')`
  padding-right: 55px;
`;

export const ByLineTitle = styled('div')`
  font-size: small;
  color: darkslategray;
`;

const ByLineItem = ({ title, value }: { title: string, value: number | string }) => {
  return (
    <ByLineBlock>
      <ByLineTitle>{title}</ByLineTitle>
      <div>{value}</div>
    </ByLineBlock>
  )
}

export default ByLineItem;
