import React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ListItemIcon from '@mui/material/ListItemIcon';

import styled from 'styled-components';

type ItemProps = {
  title: string
  description?: string
  example?: string
}

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const StyledItemIcon = styled(ListItemIcon)`
  margin-top: 6px;
`;

export const Item = ({ title, description, example }: ItemProps) => {
  return (
    <ListItem alignItems="flex-start">
      <StyledItemIcon>
        <TextSnippetIcon fontSize="small" />
      </StyledItemIcon>
      <ContentWrapper>
        <Typography
          variant="h6"
        >
          {title}
        </Typography>
        {description && (
          <Typography
            sx={{ mb: 1.5 }} color="text.secondary"
          >
            {description}
          </Typography>
        )}
        {example && (
          <Typography
            variant="body2"
          >
            {example}
          </Typography>
        )}
      </ContentWrapper>
    </ListItem>
  )
}

const ItemList = ({ children }: { children: JSX.Element[] }) => (
  <List>
    {children}
  </List>
)

export default ItemList;
