import React, { useState } from 'react';
import Skeleton from '@mui/material/Skeleton';
import styled from 'styled-components';

interface ImageWithSkeletonProps {
  alt?: string
  src: string
  height: string
  width: string
}

const Container = styled('div')`
  height: ${props => props.height}px;
  width: ${props => props.width}px;
`;

const ImageWithSkeleton = ({ alt, height, width, src }: ImageWithSkeletonProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoaded = () => {
    setIsLoaded(true);
  };

  return (
    <Container height={height} width={width}>
      {!isLoaded && <Skeleton height={`${height}px`} width={`${width}px`} variant="rectangular" />}
      <img alt={alt} src={src} onLoad={handleImageLoaded} />
    </Container>
  );
};

export default ImageWithSkeleton;
