import React from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';

function getPageTitle(title) {
  switch(title) {
    case '/para-por':
      return 'Para/Por';
    case '/subjunctive-indicative':
      return 'Subjunctive/Indicative';
    case '/preterite-imperfect':
      return 'Preterite/Imperfect';
    case '/verb-cloze':
      return 'Conjugations';
    case '/verb-cloze/practice':
      return 'Conjugations';
    case '/ser-estar':
      return 'Ser/Estar';
    case '/verb-preposition':
      return 'Verbs with Prepositions';
    default:
      return '';
  }
}

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 1.05rem;
`;

const PageTitle = ({ pathname }) => {
  const title = getPageTitle(pathname);

  return (
    <Title data-cy="page-title">{title}</Title>
  );
}

export default PageTitle;
