import React from 'react';
import Typography from '@mui/material/Typography';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { Link as RouterLink, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ByLineItem, { ByLineTitle } from './ByLineItem';
import ItemList, { Item } from '@/components/Static/Common/ItemList';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

const articleRegex = /^((la)|(el))\s+/i

const StyledLink = styled(Link)`
  line-height: 0;
  margin-left: 35px;

  img {
    max-height: 26px;
  }
`;

const WordCloudWrapper = styled('div')`
  text-align: center;
  padding-bottom: 12px;
  padding-top: 12px;

  img {
    height: auto;
    width: 100%;
    max-width: 800px;
  }
`;

export const PageTitle = styled(Stack)`
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid gray;
`;

const sortedItems = (document) => {
  if (!document) return [];

  // Exclude any records without a word
  const filteredItems = document.data['vocabulary-item'].filter((item) => item.word);

  return filteredItems.sort((a, b) => {
    const aWithoutArticles = a.word.replace(articleRegex, '')
    const bWithoutArticles = b.word.replace(articleRegex, '')

    return String(aWithoutArticles).localeCompare(String(bWithoutArticles));
  })
}

const getSource = (link: string): string => {
  if (link.includes('netflix')) {
    return '/netflix.png'
  }

  if (link.includes('rtve')) {
    return '/rtve.png';
  }

  if (link.includes('nbc')) {
    return '/telemundo.png';
  }
}

const VocabularyPage = () => {
  const { show, season } = useParams();

  const [document] = usePrismicDocumentByUID('show', show, {
    graphQuery: `{
      show {
        display_name
        source
        year
        region
        episode_count
        original_name
        season {
          word_cloud
          source
          vocabulary_list {
            display_name
            vocabulary-item
          }
        }
      }
    }`
  });

  const seasonNumber = (season ?? 1) - 1;
  const filteredSeason = document?.data?.season[seasonNumber];
  const vocabulary_list = filteredSeason?.vocabulary_list;
  const items = sortedItems(vocabulary_list);

  return (
    <>
      {document && (
        <>
        <Helmet>
          <title>SpanishInAMinute - {document.data?.display_name}</title>
          <link rel="canonical" href={`https://spanishinaminute.com/vocabulary/${show}`} />
          <meta name="description" content={`Vocabulary list for ${document.data?.display_name}`} />
          <meta name="keywords" content="Spanish, Vocabulary, TV, Streaming" />
        </Helmet>

          <PageTitle>
            <Stack alignItems="center" direction="row">
              <Typography variant="h4" component="h1">
                {document.data?.display_name}
              </Typography>
              <StyledLink component={RouterLink} variant="h6" underline="none" to={document?.data?.source?.url}>
                <img src={getSource(document?.data?.source?.url)} />
              </StyledLink>
            </Stack>
            <ByLineTitle>{document?.data?.original_name}</ByLineTitle>
          </PageTitle>
          <Stack direction="row" sx={{ marginTop: '-4px' }}>
            <ByLineItem title="Year" value={document.data.year} />
            <ByLineItem title="Region" value={document.data.region} />
            <ByLineItem title="Episodes" value={document.data.episode_count} />
          </Stack>
          <WordCloudWrapper>
            <img width="800" height="200" alt={filteredSeason?.word_cloud?.alt} src={filteredSeason?.word_cloud?.url} />
          </WordCloudWrapper>
          <Paper sx={{ marginBottom: '16px' }}>
            <ItemList>
              {items.map((item) => {
                return (
                  <Item title={item.word.toLowerCase()} key={item.word} />
                );
              })}
            </ItemList>
          </Paper>
        </>
      )}
    </>
  );
};

export default VocabularyPage;
